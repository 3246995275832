import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({
    isCompression: false
});
Vue.use(Vuex)


import auth from "../modules/auth";
import general from "../modules/general";
import formats from "@/modules/formats";
import tenders from "@/modules/tenders";
import marketStudies from "@/modules/marketStudies";
import users from "@/modules/users";

import annexes from "@/modules/annexes";
import bases from "@/modules/bases";
import bonds from "@/modules/bonds";
import contract from "@/modules/contract";
import doubts from "@/modules/doubts";
import statusTender from "@/modules/statusTender";
import openingProposals from "@/modules/openingProposals";
import opinions from "@/modules/opinions";
import proposals from "@/modules/proposals";
import rulings from "@/modules/rulings";
import sufficiency from "@/modules/sufficiency";
import anx_performnace_bond from "@/modules/anx_performnace_bond";
import anx_spec_tecnic from "@/modules/anx_spec_tecnic";
import constancy_bases from "@/modules/constancy_bases";
import ja_attendance from "@/modules/ja_attendance";
import ja_minutes from "@/modules/ja_minutes";
import ja_publication from "@/modules/ja_publication";
import ja_retirement from "@/modules/ja_retirement";
import ja_surveillance_unit from "@/modules/ja_surveillance_unit";
import ja_attendance_list from "@/modules/ja_attendance_list";
import openingDesert from "@/modules/openingDesert";
import technicalOpinions from "@/modules/technicalOpinions";
import presentationOpinions from "@/modules/presentationOpinions";

export default new Vuex.Store({
    state: {
        draw: true,
        over: false,
        messages: []
    },
    plugins: [createPersistedState({
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key)
        }
    })],
    mutations: {
        setDraw(state) {
            state.draw = !state.draw;
        },
        setOver(state, over) {
            state.over = over;
        },
        setMessages(state, message){
            if(message === 'clear'){
                state.messages = [];
            }else{
                state.messages.push(message);
            }
        }
    },
    actions: {
        setDraw({commit}) {
            commit('setDraw')
        },
        setOver({commit}, over) {
            commit('setOver', over);
        },
        setMessages({commit}, message){
            commit('setMessages', message);
        }
    },

    modules: {
        auth,
        general,
        formats,
        tenders,
        marketStudies,
        annexes,
        anx_performnace_bond, 
        anx_spec_tecnic, 
        bases,
        constancy_bases,
        bonds,
        contract,
        doubts,
        openingProposals,
        opinions,
        proposals,
        rulings,
        statusTender,
        sufficiency,
        ja_attendance, 
        ja_minutes, 
        ja_publication, 
        ja_retirement, 
        ja_surveillance_unit,
        users,
        ja_attendance_list,
        openingDesert,
        technicalOpinions,
        presentationOpinions
    },
    getters: {
        getDraw(state) {
            return state.draw
        },
        getOver(state) {
            return state.over;
        },
        getMessages(state){
            return state.messages;
        }
    }
})
