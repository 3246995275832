import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
      options: {
        customProperties: false,
      },
    themes: {
      light: {
        primary: '#007268', //#283046
        background:'#ffffff',
        secondary: '#000000',
        input: '#ffffff',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark:{
        primary: '#3e3783', //#283046
        background:'#283046',
        secondary: '#ffffff',
        input: '#ffffff',
      }

    },
  },
});
