import {requestFetch} from '../store/config'

export default {
    namespaced: true,
    mutations: {},
    actions: {

        async findUsers({commit}) {
            const response = await requestFetch(`v1/user`, 'GET');
            return response.users;
        },
        async findOneUsers({commit}, id) {
            const response = await requestFetch(`v1/user/${id}`, 'GET');
            return response;
        },

        async changeStatus({commit}, {id,data}) {
            const response = await requestFetch(`v1/user/changeStatus/${id}`, 'PUT', data);
            return response;
        },
        async findDepartments({commit}) {
            const response = await requestFetch(`v1/user/departments`, 'GET');
            return response.departments;
        },
        async deleteDepartments({commit},id) {
            const response = await requestFetch(`v1/user/departments/${id}`, 'DELETE');
            return response;
        },
        async findOneDepartments({commit}, id) {
            const response = await requestFetch(`v1/user/departments/${id}`, 'GET');
            return response;
        },
        async createDepartments({commit}, data) {
            const response = await requestFetch(`v1/user/departments`, 'POST', data);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        async editDepartments({commit}, {id,data}) {
            const response = await requestFetch(`v1/user/departments/${id}`, 'PUT', data);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        async registerUser({commit}, user) {
            const response = await requestFetch(`v1/auth/register`, 'POST', user);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
        async findOneUser({commit}, id) {
            const response = await requestFetch(`v1/user/${id}`, 'GET');
            return response;
        },
        async changePasswordUser({commit}, {id,data}) {
            const response = await requestFetch(`v1/auth/changePassword/${id}`, 'PUT', data);
            if (response.msg) {
                return true
            } else {
                return response.error
            }
        },
    },
    getters: {

    }

}
