import store from "./index";

const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/' : 'https://asej.lava.mx/api/';
export const URL_API_IMAGES = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://asej.lava.mx';

export const requestFetch = async (path, method, data, form_data = null ) => {
    const myHeaders = new Headers();
    if (form_data === true){
        myHeaders.append("Authorization", store.getters["auth/getToken"]);
    }else{
        myHeaders.append("Authorization", store.getters["auth/getToken"]);
        myHeaders.append("Content-Type", "application/json");
    }

    let requestOptions;
    if (form_data === true){
        requestOptions = {
            method: method,
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };
    }else{
        requestOptions = {
            method: method,
            headers: myHeaders,
            body: JSON.stringify(data) || null,
            redirect: 'follow'
        };
    }


    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }

}

export const requestFetchFile = async (path, method, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "*");
    const requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.blob();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return { error };
    }
};
