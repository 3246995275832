import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createAnxPerformance({commit}, data) {
            const response = await requestFetch(`v1/anx_performnace_bond/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateAnxPerformance({commit}, {id,data}) {
            const response = await requestFetch(`v1/anx_performnace_bond/update/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findOneAnxPerformance({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/anx_performnace_bond/${id}/anx_performance/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormatAnxPB({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/anx_performnace_bond/${id}/format/${tenderId}`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFileAnxPB({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/anx_performnace_bond/${id}/download/${externalId}`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `anx-fianza-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
