import {requestFetch} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createFormats({commit}, data) {
            const response = await requestFetch(`v1/format/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },

        async updateFormats({commit}, {data,id}) {
            const response = await requestFetch(`v1/format/content/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findAllFormats({commit}, ) {
            const response = await requestFetch(`v1/format`, 'GET');
            return response
        },

        async findOneFormats({commit},id ) {
            const response = await requestFetch(`v1/format/${id}`, 'GET');
            return response
        },

        async findAllCards({commit},id ) {
            const response = await requestFetch(`v1/format/${id}/cards`, 'GET');
            if (response.cards){
                return response.cards
            }else{
                return []
            }

        },
    },
    getters: {
    }

}
