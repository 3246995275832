import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import AppLayout from "./layouts/AppLayout";
import './mixins';
import './assets/css/global-styles.css';
import jQuery from 'jquery';
window.jQuery = jQuery;
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'

import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css";

Vue.use(VueModalTor, {

});

Vue.component('AppLayout', AppLayout);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

