<template>
  <AppLayout>
      <router-view></router-view>
  </AppLayout>
</template>

<script>

export default {
  name: "App",

  components: {},

  data: () => ({

  }),
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === 'true') {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  methods:{
    changeColor(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("dark_theme",this.$vuetify.theme.dark);
    },
  }
};
</script>

