import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createStautsTender({commit}, data) {
            const response = await requestFetch(`v1/status_tender/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateStautsTender({commit}, {id, tenderId,data}) {
            const response = await requestFetch(`v1/status_tender/update/${id}/${tenderId}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async getEstatusLicitacion({commit}, {tenderId}) {
            const response = await requestFetch(`v1/status_tender/${tenderId}`, 'GET');
            if (response.statusTender){
                return response.statusTender;
            }else{
                return null;
            }
        },
        async findOneEstatusLicitacion({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/status_tender/${id}/status_tender/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormat({commit}, {id}) {
            const response = await requestFetch(`v1/status_tender/${id}/format`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFile({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/status_tender/${id}/download`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `estudio-mercado-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
