import {requestFetch} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createTenders({commit}, data) {
            const response = await requestFetch(`v1/tender/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },


        async findAllTenderCatalog({commit}, data) {
            const response = await requestFetch(`v1/tender/all/catalog`, 'GET');
            return response;
        },
        async updateTenders({commit}, {id,data}) {
            const response = await requestFetch(`v1/tender/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findAllTenders({commit}, ) {
            const response = await requestFetch(`v1/tender`, 'GET');
            return response
        },

        async findOneTenders({commit}, id) {
            const response = await requestFetch(`v1/tender/${id}`, 'GET');
            if (response.tender){
                return response.tender
            }else{
                return null;
            }

        },
    },
    getters: {
    }

}
