import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createJAMinutes({commit}, data) {
            const response = await requestFetch(`v1/ja_minutes/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateJAMinutes({commit}, {id,data}) {
            const response = await requestFetch(`v1/ja_minutes/update/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findOneJAMinutes({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/ja_minutes/${id}/ja_minutes/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormatJAMinutes({commit}, {id}) {
            const response = await requestFetch(`v1/ja_minutes/${id}/format`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFileJAMinutes({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/ja_minutes/${id}/download`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `acta-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
