import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createConstancyBases({commit}, data) {
            const response = await requestFetch(`v1/constancy_bases/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async createConstancyBasesFile({commit}, data) {
            const formdata = new FormData();
            formdata.append("tenderId", data.tenderId);
            formdata.append("formatosId", data.formatosId);
            formdata.append("file", data.url);
            const response = await requestFetch(`v1/constancy_bases/create_file`, 'POST', formdata,true);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateBasesFile({commit}, {id,data}) {
            const formdata = new FormData();
            formdata.append("file", data);
            const response = await requestFetch(`v1/constancy_bases/update_file/${id}`, 'PUT', formdata, true);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateConstancyBases({commit}, {id,data}) {
            const response = await requestFetch(`v1/constancy_bases/update/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findOneConstancyBases({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/constancy_bases/${id}/constancy_bases/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async findOneByTenderFile({commit},  tenderId) {
            const response = await requestFetch(`v1/constancy_bases/${tenderId}/constancy_bases_file`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async findOneByTender({commit},  tenderId) {
            const response = await requestFetch(`v1/constancy_bases/${tenderId}/by_tender`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormatCB({commit}, {id}) {
            const response = await requestFetch(`v1/constancy_bases/${id}/format`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFileCB({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/constancy_bases/${id}/download`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `bases-constancia-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
