import store from '../store';

const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : 'produccion';

const requestFetch = async (path, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: data ? 'POST' : 'GET',
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}

export default {
    namespaced: true,
    state: {
        user: {},
        token: '',
        sidebarMenu: '',
        sidebarSubMenu: '',
        errors: []
    },
    mutations: {
        setMenu(state, sidebarMenu) {
            state.sidebarMenu = sidebarMenu
        },
        setSubMenu(state, sidebarSubMenu) {
            state.sidebarSubMenu = sidebarSubMenu
        },
    },
    actions: {

        //CREATE USER
        // async registerUser({ commit }, user) {
        //     commit('setErrors', 'clear');
        //     const response = await requestFetch('/api/auth/register',user);
        //     if(response.error) commit('setErrors', response.error);
        //     return response;
        // },

        // GET USERS
        async setThemeColor({ commit }, data) {

            localStorage.setItem("dark_theme",data);
        },
        setDataMenu({commit}, sidebarMenu) {
            commit('setMenu', sidebarMenu)
        },
        setDataSubMenu({commit}, sidebarSubMenu) {
            commit('setSubMenu', sidebarSubMenu)
        },
        async setMenu({ commit }, data) {

            localStorage.setItem("dark_theme",data);
        },


    },
    getters: {
        getMenu(state) {
            return state.sidebarMenu;
        },
        getSubMenu(state) {
            return state.sidebarSubMenu;
        }

    }

}
