import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/licitaciones',
        name: 'Tenders',
        component: () => import('../views/Tenders/Index'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/licitaciones_editar/:id',
        name: 'TendersDetails',
        component: () => import('../views/Tenders/Detail.vue'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/licitaciones_documentos/:id',
        name: 'DetailsCards',
        component: () => import('../views/Tenders/DetailCards'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/nueva_licitacion',
        name: 'CreateTenders',
        component: () => import('../views/Tenders/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/layouts_lista',
        name: 'ListLayouts',
        component: () => import('../views/Formats/Index'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/layouts/:id',
        name: 'DetailLayout',
        component: () => import('../views/Formats/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/nuevo_layout',
        name: 'CreateDocuments',
        component: () => import('../views/Formats/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/detalle_layout',
        name: 'DetailDocuments',
        component: () => import('../views/Tenders/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },

    {
        path: '/departamentos',
        name: 'DepartmentsList',
        component: () => import('../views/Departments/Index'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/crear_departamentos',
        name: 'DepartmentsCreate',
        component: () => import('../views/Departments/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/editar_departamentos/:id',
        name: 'DepartmentsEdit',
        component: () => import('../views/Departments/Detail.vue'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        meta: {
            isLogout: true
        }
    },
    {
        path: '/usuarios',
        name: 'UsersList',
        component: () => import('../views/Users/Index'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/crear_usuarios',
        name: 'CreateUsers',
        component: () => import('../views/Users/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    {
        path: '/editar_usuario/:id',
        name: 'ChangePassword',
        component: () => import('../views/Users/ChangePassword'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated: true
        }
    },
    // ### ANNEXES ### 
    {
        path: '/crear_anexo/:id/:formatId',
        name: 'CreateAnnexe',
        component: () => import('../views/Annexes/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_anexo/:id/:marketId',
        name: 'DetailAnnexe',
        component: () => import('../views/Annexes/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### BASES ###
    {
        path: '/crear_bases/:id/:formatId',
        name: 'CreateBases',
        component: () => import('../views/Bases/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_bases/:id/:marketId',
        name: 'DetailBases',
        component: () => import('../views/Bases/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### BONDS ###
    {
        path: '/crear_fianzas/:id/:formatId',
        name: 'CreateBonds',
        component: () => import('../views/Bonds/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_fianzas/:id/:marketId',
        name: 'DetailBonds',
        component: () => import('../views/Bonds/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### CLARIFICATION MEETINGS ###
    {
        path: '/crear_junta_aclaracion/:id/:formatId',
        name: 'CreateClarificationMeetings',
        component: () => import('../views/ClarificationMeetings/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_junta_aclaracion/:id/:marketId',
        name: 'DetailClarificationMeetings',
        component: () => import('../views/ClarificationMeetings/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### CONTRACT ###
    {
        path: '/crear_contrato/:id/:formatId',
        name: 'CreateContract',
        component: () => import('../views/Contract/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_contrato/:id/:marketId',
        name: 'DetailContract',
        component: () => import('../views/Contract/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### DOUBTS ###
    {
        path: '/crear_dudas/:id/:formatId',
        name: 'CreateDoubts',
        component: () => import('../views/Doubts/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_dudas/:id/:marketId',
        name: 'DetailDoubts',
        component: () => import('../views/Doubts/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### MARKET STUDY ###
    {
        path: '/crear_estudio_mercado/:id/:formatId',
        name: 'CreateMarketStudy',
        component: () => import('../views/MarketStudy/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_estudio_mercado/:id/:marketId',
        name: 'DetailMarketStudy',
        component: () => import('../views/MarketStudy/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### OPINIONS ###
    {
        path: '/crear_dictamen/:id/:formatId',
        name: 'CreateOpinions',
        component: () => import('../views/Opinions/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_dictamen/:id/:marketId',
        name: 'DetailOpinions',
        component: () => import('../views/Opinions/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### PROPOSALS ###
    {
        path: '/crear_propuesta/:id/:formatId',
        name: 'CreateProposals',
        component: () => import('../views/Proposals/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_propuesta/:id/:marketId',
        name: 'DetailProposals',
        component: () => import('../views/Proposals/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### RULINGS ###
    {
        path: '/crear_fallo/:id/:formatId',
        name: 'CreateRulings',
        component: () => import('../views/Rulings/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_fallo/:id/:marketId',
        name: 'DetailRulings',
        component: () => import('../views/Rulings/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### SUFFICIENCY ###
    {
        path: '/crear_suficiencia/:id/:formatId',
        name: 'CreateSufficiency',
        component: () => import('../views/Sufficiency/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_suficiencia/:id/:marketId',
        name: 'DetailSufficiency',
        component: () => import('../views/Sufficiency/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### Opening Proposals ###
    {
        path: '/crear_apertura_propuestas/:id/:formatId',
        name: 'CreateOpeningProposals',
        component: () => import('../views/OpeningProposals/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_apertura_propuestas/:id/:marketId',
        name: 'DetailOpeningProposals',
        component: () => import('../views/OpeningProposals/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### Proposals ###
    {
        path: '/crear_propuestas/:id/:formatId',
        name: 'CreateProposals',
        component: () => import('../views/Proposals/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_propuestas/:id/:marketId',
        name: 'DetailProposals',
        component: () => import('../views/Proposals/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### OPINIONS ###
    {
        path: '/crear_dictamen/:id/:formatId',
        name: 'CreateOpinions',
        component: () => import('../views/Opinions/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_dictamen/:id/:marketId',
        name: 'DetailOpinions',
        component: () => import('../views/Opinions/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### RULINGS ###
    {
        path: '/crear_fallo/:id/:formatId',
        name: 'CreateRulings',
        component: () => import('../views/Rulings/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_fallo/:id/:marketId',
        name: 'DetailRulings',
        component: () => import('../views/Rulings/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### BONDS ###
    {
        path: '/crear_fianza/:id/:formatId',
        name: 'CreateBonds',
        component: () => import('../views/Bonds/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_fianza/:id/:marketId',
        name: 'DetailBonds',
        component: () => import('../views/Bonds/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    // ### CONTRACT ###
    {
        path: '/crear_contrato/:id/:formatId',
        name: 'CreateContract',
        component: () => import('../views/Contract/Create'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
    {
        path: '/edit_contrato/:id/:marketId',
        name: 'DetailContract',
        component: () => import('../views/Contract/Detail'),
        meta: {
            layout: 'DashboardLayout',
            isAuthenticated:true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// let roleRedirect;
// if (localStorage.getItem('user')) {
//   switch (JSON.parse(localStorage.getItem('user')).user_rol_id) {
//     case 1:
//       roleRedirect = '/admin';
//       break;
//     case 2:
//       roleRedirect = '/user';
//       break;
//     case 4:
//       roleRedirect = '/reception'
//   }
// }

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);
    if (to.meta.isAuthenticated) {

        await store.dispatch('auth/userme');
        if (!store.getters["auth/getUser"]) {
            next('/login')
        } else {
            next();
        }

//       if (to.meta.roleAdmin) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 1) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }


//       if (to.meta.roleUser) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 2) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }

//       if (to.meta.roleReception) {
//         if (JSON.parse(localStorage.getItem('user')).user_rol_id === 4) {
//           next()
//         } else {
//           next(roleRedirect);
//         }
//       } else {
//         next();
//       }
    } else {
        next();
    }


    if (to.meta.isLogout) {
        if (store.getters["auth/getUser"]) {
            next('/');
        } else {
            next();
        }
    } else {
        next();
    }

})

export default router
