import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
export const mixins = {
    methods: {
        reformatDate(d) {
            if (d) {
                const da = new Date(d);
                const day = da.getDate();
                const month = da.getMonth() + 1;
                const year = da.getFullYear();
                let dayfinal;
                let monthfinal;
                if (day < 10) {
                    dayfinal = '0' + day
                } else {
                    dayfinal = day
                }
                if (month < 10) {
                    monthfinal = '0' + month
                } else {
                    monthfinal = month
                }
                return dayfinal + '-' + monthfinal + '-' + year;
            }

        },


        sweetAlertProduct(text, text_color, icon, background, iconcolor, position) {
            this.$swal.fire({
                position: position,
                title: `<small style="color: ${text_color}">${text}</small>`,
                timer: 2000,
                icon: icon,
                background: background,
                showConfirmButton: false,
                iconColor: iconcolor,
                showClass: {
                    popup: 'animate_animated animate_fadeInDown'
                },
                hideClass: {
                    popup: 'animate_animated animate_fadeOutUp'
                }
            })
        },
        sweetAlertWarning(text) {
            this.$swal.fire({
                position: "center",
                title: `<small style="color: black">${text}</small>`,
                timer: 4000,
                icon: "warning",
                background: 'white',
                showConfirmButton: true,
                confirmButtonText: 'Listo',
                confirmButtonColor: '#007268',
                iconColor: '#007268',
                showClass: {
                    popup: 'animate_animated animate_fadeInDown'
                },
                hideClass: {
                    popup: 'animate_animated animate_fadeOutUp'
                }
            })
        },
        sweetAlertError(text) {
            this.$swal.fire({
                position: "center",
                title: `<small style="color: black">${text}</small>`,
                timer: 2000,
                icon: "error",
                background: 'white',
                showConfirmButton: true,
                confirmButtonText: 'Listo',
                confirmButtonColor: '#007268',
                iconColor: 'red',
                showClass: {
                    popup: 'animate_animated animate_fadeInDown'
                },
                hideClass: {
                    popup: 'animate_animated animate_fadeOutUp'
                }
            })
        },
        sweetAlertSuccess(text) {
            this.$swal.fire({
                position: "center",
                title: `<small style="color: black">${text}</small>`,
                timer: 2000,
                icon: "success",
                background: 'white',
                showConfirmButton: true,
                confirmButtonText: 'Listo',
                confirmButtonColor: '#007268',
                iconColor: '#007268',
                showClass: {
                    popup: 'animate_animated animate_fadeInDown'
                },
                hideClass: {
                    popup: 'animate_animated animate_fadeOutUp'
                }
            })
        },

        formatNumber(a) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            })
            const h = formatter.format(a).split('$')
            return ' $ ' + ' ' + h[1];
        },

        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

    }
}

Vue.mixin(mixins);