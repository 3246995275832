import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createContract({commit}, data) {
            const response = await requestFetch(`v1/contract/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateContract({commit}, {id,data}) {
            const response = await requestFetch(`v1/contract/update/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findOneContract({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/contract/${id}/contract/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormat({commit}, {id}) {
            const response = await requestFetch(`v1/contract/${id}/format`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFile({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/contract/${id}/download`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `contrato-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
