import {requestFetch, requestFetchFile} from '@/store/config';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async createOpeningDesert({commit}, data) {
            const response = await requestFetch(`v1/opening_desert/create`, 'POST', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async updateOpeningDesert({commit}, {id,data}) {
            const response = await requestFetch(`v1/opening_desert/update/${id}`, 'PUT', data);
            if (response.msg){
                return  true;
            }else{
                return response
            }
        },
        async findOneOpeningDesert({commit}, {id, tenderId}) {
            const response = await requestFetch(`v1/opening_desert/${id}/opening_desert/${tenderId}`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },

        async findOneOpeningDesertTender({commit},  tenderId) {
            const response = await requestFetch(`v1/opening_desert/${tenderId}/opening_desert_tender`, 'GET');
            if (response.tender){
                return response.tender;
            }else{
                return null;
            }
        },
        async generateHtmlFormatOpeningDesert({commit}, {id}) {
            const response = await requestFetch(`v1/opening_desert/${id}/format`, 'GET');
            if (response.data){
                return response.data;
            }else{
                return null;
            }
        },
        async downloadFileOpeningDesert({commit}, {id,externalId}) {
            const response = await requestFetchFile(`v1/opening_desert/${id}/download`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = `propuesta-desierto-${id}-${externalId}` + '.'+'docx';
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
    },
    getters: {
    }

}
